<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-col cols="12">
          <redirect class="" title="Taxes" route="website.settings.taxes" />
        </v-col>
        <v-col cols="12" class="mt-5">
          <v-row>
            <v-col cols="12" md="4">
              <h3>Tax regions</h3>
              <p class="mt-3">
                Manage how your store charges sales tax. Check with a tax expert to understand your tax obligations.
              </p>
            </v-col>
            <v-col cols="12" md="8" class="elevation-form px-5 py-5" v-if="isLoading">
              <SkeletonLoaders type="card-heading, list-item-three-line" />
            </v-col>
            <v-col cols="12" md="8" class="elevation-form px-5 py-5" v-else>
              <v-row>
                <v-col cols="12">
                  <h3>Country tax</h3>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="tax.threshold" hide-details prefix="Threshold |"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    @blur="$v.tax.name.$touch()"
                    v-model="tax.name"
                    hide-details
                    prefix="Tax name |"
                  ></v-text-field>
                  <p class="red--text" v-if="$v.tax.name.$dirty && !$v.tax.name.required">Tax name is required.</p>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    @blur="$v.tax.rate.$touch()"
                    v-model="tax.rate"
                    hide-details
                    prefix="Tax rate |"
                    suffix="%"
                  ></v-text-field>
                  <p class="red--text" v-if="$v.tax.rate.$dirty && !$v.tax.rate.required">Tax rate is required.</p>
                  <p class="red--text" v-if="$v.tax.rate.$dirty && !$v.tax.rate.decimal">Tax rate must be a number.</p>
                </v-col>
              </v-row>
              <hr class="mt-3" v-if="provinces && provinces.length > 0" />
              <v-row class="mt-3" v-if="provinces && provinces.length > 0">
                <v-col cols="12">
                  <div class="d-flex align-center justify-content-between">
                    <h4>State</h4>
                    <v-btn @click="showModal" class="ml-auto router-link"> Collect tax </v-btn>
                    <v-btn @click="loadState" v-if="tax.country === 'US'" class="ml-2">
                      Apply Tax US
                    </v-btn>
                  </div>
                  <p class="mt-3">Region tax will be used for tax calculation instead of country tax.</p>
                  <p>
                    You are collecting tax in {{ taxes.length }} state in the
                    <span v-if="countries.find(c => c.code === tax.country)">
                      {{ countries.find(c => c.code === tax.country).name }}
                    </span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Region name</th>
                          <th>Threshold</th>
                          <th>Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tx in taxes" :key="tx.id">
                          <td>
                            {{ tx.name }}
                          </td>
                          <td v-if="provinces && provinces.find(p => p.code === tx.state)">
                            {{ provinces.find(p => p.code === tx.state).name }}
                          </td>
                          <td>{{ currency.priceFormat(tx.threshold || 0) }}</td>
                          <td>{{ tx.rate }}%</td>
                          <td>
                            <div class="text-right">
                              <v-btn @click="showModal(tx)" class="router-link">
                                <i class="fas fa-pen"></i>
                              </v-btn>
                              <v-btn @click="removeTax(tx.id, 'state')" class="router-link ml-2">
                                <i class="fas fa-trash"></i>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="8" class="elevation-form px-5 py-5 mt-5" v-if="tax">
              <div class="d-flex align-center justify-between">
                <v-btn
                  :loading="isLoadingAction"
                  class="white--text"
                  :disabled="isLoadingAction"
                  @click="removeTax(tax.id)"
                  color="red"
                >
                  Delete country
                </v-btn>
                <v-btn
                  class="ml-auto"
                  :loading="isLoadingAction"
                  :disabled="isLoadingAction"
                  @click="save"
                  color="primary"
                >
                  Save
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <modal model="modal" title="Collect tax" :callback="submit">
      <template slot="content">
        <v-row>
          <v-col cols="12">
            <h4>Add a new region for tax collection. You can use default values or fil at your disposal.</h4>
          </v-col>
          <v-col cols="12">
            <h3>State</h3>
            <v-combobox
              v-if="
                provinces &&
                  provinces.length > 0 &&
                  provinces.filter(p => taxes.findIndex(t => t.state === p.code) === -1).length > 0
              "
              item-text="name"
              item-value="code"
              v-model="formData.state"
              @blur="$v.formData.state.$touch()"
              :items="
                provinces.filter(p => taxes.findIndex(t => t.state === p.code && t.country === tax.country) === -1)
              "
              placeholder="Select state"
              hide-details
              :multiple="false"
            ></v-combobox>
            <p class="red--text" v-if="$v.formData.state.$dirty && !$v.formData.state.required">State is required.</p>
          </v-col>
          <v-col cols="12">
            <h3>Threshold</h3>
            <v-text-field
              v-model="formData.threshold"
              hide-details
              :placeholder="currency.currencySign() + '0.00'"
            ></v-text-field>
            <small>Tax will be collected if order value is greater than or equal to threshold</small>
          </v-col>
          <v-col cols="12">
            <h3>Tax name</h3>
            <v-text-field
              placeholder="Tax name"
              @blur="$v.formData.name.$touch()"
              v-model="formData.name"
              hide-details
            ></v-text-field>
            <p class="red--text" v-if="$v.formData.name.$dirty && !$v.formData.name.required">Tax name is required.</p>
          </v-col>
          <v-col cols="12">
            <h3>Tax rate</h3>
            <v-text-field
              placeholder="10"
              suffix="%"
              @blur="$v.formData.rate.$touch()"
              v-model="formData.rate"
              hide-details
            ></v-text-field>
            <p class="red--text" v-if="$v.formData.rate.$dirty && !$v.formData.rate.required">Tax rate is required.</p>
            <p class="red--text" v-if="$v.formData.rate.$dirty && !$v.formData.rate.decimal">
              Tax rate must be a number.
            </p>
          </v-col>
        </v-row>
      </template>
    </modal>
  </div>
</template>
<script>
import { taxApi } from '@/apis/tax';
import { required, minLength, maxLength, between, decimal } from 'vuelidate/lib/validators';
import { orderApi } from '@/apis/order';
import currency from '@/helpers/currency';

export default {
  data() {
    return {
      tax: {
        country: '',
        threshold: 0,
        name: '',
        rate: '',
      },
      taxes: [],
      countries: [],
      formData: {
        state: '',
        country: '',
        threshold: 0,
        name: '',
        rate: '',
      },
      modal: false,
      isLoading: false,
      isLoadingAction: false,
      currency,
    };
  },
  validations: {
    tax: {
      country: {
        required,
      },
      name: {
        required,
      },
      rate: {
        required,
        decimal,
      },
    },
    formData: {
      name: {
        required,
      },
      state: {
        required,
      },
      rate: {
        required,
        decimal,
      },
    },
  },
  computed: {
    provinces() {
      let country = this.countries.find(c => c.code === this.tax.country);
      if (country) {
        return country.provinces;
      }
      return [];
    },
  },
  mounted() {
    this.loadData();
    this.loadCountries();
  },
  methods: {
    showModal(tax) {
      if (tax) {
        let province = this.provinces.find(p => p.code === tax.state);
        this.formData = { ...tax, state: province || tax.state };
      } else {
        this.formData = {
          country: '',
          threshold: 0,
          name: '',
          rate: '',
        };
      }
      this.$v.formData.$reset();
      this.modal = true;
    },
    async loadCountries() {
      try {
        let countriesData = await orderApi.getCountry();
        this.countries = countriesData.data;
      } catch (error) {
        console.log(error);
      }
    },
    async loadState() {
      try {
        this.isLoading = true;
        await taxApi.listUS({ country: this.tax.country });
        let resList = await taxApi.list({ country: this.tax.country });
        this.taxes = resList.data.filter(t => t.state);
        await this.loadData();
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async loadData() {
      try {
        this.isLoading = true;
        let res = await taxApi.getById(this.$route.params.id);
        this.tax = res.data;

        let resList = await taxApi.list({ country: this.tax.country });
        this.taxes = resList.data.filter(t => t.state);

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async submit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        let formData = {
          ...this.formData,
          country: this.tax.country,
          state: this.formData.state?.code || this.formData.state,
        };
        if (!formData.threshold) {
          formData.threshold = 0;
        }
        delete formData.isTrusted;
        delete formData.storeId;
        try {
          this.isLoadingAction = true;
          if (!formData.id) {
            let res = await taxApi.create(formData);
          } else {
            let res = await taxApi.update(formData);
          }
          this.loadData();
          this.modal = false;
          this.isLoadingAction = false;
        } catch (error) {
          this.isLoadingAction = false;
          console.log(error);
        }
      }
    },
    async removeTax(id, type) {
      try {
        this.isLoadingAction = true;
        await taxApi.deleteById(id);
        this.isLoadingAction = false;
        if (type === 'state') {
          this.loadData();
        } else {
          this.$router.push({ name: 'website.settings.taxes' });
        }
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
    async save() {
      this.$v.tax.$touch();
      if (!this.$v.tax.$invalid) {
        try {
          this.isLoadingAction = true;
          if (!this.tax.threshold) {
            this.tax.threshold = 0;
          }
          await taxApi.update(this.tax);
          this.isLoadingAction = false;
        } catch (error) {
          this.isLoadingAction = false;
        }
      }
    },
  },
};
</script>
